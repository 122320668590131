import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const About = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="About me" />
      <div className="flex flex-col sm:flex-row gap-16 sm:gap-0 items-center text-center sm:text-left p-4 sm:p-32 sm:h-[calc(100vh-128px)]">
        <div className="sm:basis-1/2 flex flex-col w-full gap-10 items-center text-center sm:text-left h-full">
          <h1 className="text-3xl sm:text-5xl font-semibold">
            About me and hortensia.tech
          </h1>
          <div className="text-lg sm:text-2xl font-normal flex flex-col gap-6">
            <p>
              If you're seeking a run-of-the-mill templated web and software
              solution, you've come to the wrong place. I specialize in
              providing{" "}
              <b>unique high-quality web, mobile or software-based solutions</b>{" "}
              for companies looking to <b>improve their ROI</b> and grow their
              business.
            </p>
            <p>
              The goal is to <b>add value to your business</b> by improving or
              rebuilding existing systems—creating intuitive{" "}
              <b>solutions that are responsive and personalized</b> to your
              brand, service and product.
            </p>
            <p>
              I'll work with you to create and implement your customized IT
              solution, whether it's an IT management system, website or mobile
              app.
            </p>
          </div>
        </div>
        <div className="sm:basis-1/2 sm:w-full flex flex-col items-center justify-center">
          <StaticImage
            src="../images/aboutus-image.jpg"
            alt="Chimpmunk with his money and laptop"
            className="rounded-lg w-2/3"
          ></StaticImage>
          <span className="mt-2 italic text-gray-600">
            Created with{" "}
            <a
              className="font-bold"
              target="_blank"
              href="https://www.craiyon.com/"
              rel="noreferrer"
            >
              Craiyon
            </a>{" "}
            (formerly DALL·E mini) and upscaled with{" "}
            <a
              className="font-bold"
              target="_blank"
              href="https://ai.nero.com"
              rel="noreferrer"
            >
              NeroAI
            </a>
          </span>
        </div>
      </div>
    </Layout>
  )
}

export default About

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
